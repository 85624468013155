import {ActiveLoader, useLoading} from "vue-loading-overlay";
import {Ref} from "vue";

const currentLoader: Ref<ActiveLoader|null> = ref(null);

export function useLoader() {

  const loader = useLoading({
    isFullPage: false,
    loader: 'spinner',
    color: '#0C98DE'
  });

  function showLoader() {
    currentLoader.value = loader.show();
  }

  function hideLoader() {
    if (currentLoader.value) {
      currentLoader.value.hide();
      currentLoader.value = null;
    }
  }

  return {
    showLoader,
    hideLoader,
  }
}
